.list {
    display: flex;
    width: 100%;
    color: black;
    background-color: white;
    .listContainer {
      flex: 6;
    }
  }

input{
  color: black;
}