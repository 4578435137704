.textEditor {
    width: 100%;
    display: flex;
    color: black;
    background-color: white;
  
    .textEditorContainer {
      flex: 6;
    }

    .container{
      position: relative;
      height: 100vh;
      width: 100%;
    }

    .row{
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .editor{
      position: relative;
      height: 100%;
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .preview{
      position: relative;
      padding: 10px;
      overflow-y: scroll;
      height: 70%;
      width: 50%;
      border-left: 1px solid black;
    }

    .editor-input{
      height: 70%;
      width: 100%;
    }
}