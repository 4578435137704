@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap");


.sidebar {
    flex: 1;
    border-right: 0.5px solid rgb(230, 227, 227);
    min-height: 100vh;
    background-color: white;
    font-family: 'Nunito', sans-serif;
  
    .top {
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
  
      .logo {
        font-size: 20px;
        font-weight: bold;
        color: #6439ff;
      }
    }
  
    hr {
      height: 0;
      border: 0.5px solid rgb(230, 227, 227);
    }
  
    .center {
      padding-left: 10px;
  
      ul {
        list-style: none;
        margin: 0;
        padding: 0;
  
        .title {
          font-size: 20px;
          font-weight: bold;
          color: #c9c7c7;
          margin-top: 15px;
          margin-bottom: 5px;
        }
  
        li {
          display: flex;
          align-items: center;
          padding: 5px;
          cursor: pointer;
  
          &:hover {
            background-color: #ece8ff;
          }
  
          .icon {
            font-size: 22px;
            color: #2e31d8;
          }
  
          span{
              font-size: 15px;
              font-weight: 600;
              color: #535353;
              margin-left: 10px;
          }
        }
      }
    }
  
    .bottom{
        display: flex;
        align-items: center;
        margin: 10px;
  
        .colorOption{
            width: 20px;
            height: 20px;
            border-radius: 5px;
            border: 1px solid #7451f8;
            cursor: pointer;
            margin: 5px;
  
            &:nth-child(1){
                background-color: whitesmoke;
            }
            &:nth-child(2){
                background-color: #333;
            }
            &:nth-child(3){
                background-color: darkblue;
            }
        }
    }
  }